import request from '_utils/request'
import getSearch from "_utils/getSearch"

const get_agreement_list = () => {
  return request({
    url: '/api/login/agreement/list',
    method: 'GET',
    params: {
      channelSign: getSearch().channelSign,
    }
  })
}

const get_all_city = () => {
  return request({
    url: '/api/zoom/find-half-adress',
    method: 'GET'
  })
}

const get_all_city_no_authorization = () => {
  return request({
    url: '/api/zoom/find-nt-adress',
    method: 'GET'
  })
}

export {
  get_agreement_list,
  get_all_city,
  get_all_city_no_authorization
}