import { Toast } from 'vant'
import { getToken } from '_utils/token'

request.defaults.headers.post['Content-Type'] = 'application/json'

request.interceptors.request.use(config => {
  config.headers['authorization'] = getToken()
  return config
}, error => {
  return Promise.reject(error)
})

request.interceptors.response.use(response => {
  if (response.data.code === 30033 && !process.env.VUE_APP_IS_ONLY_LONG_FORM) {
    localStorage.clear()
    location.reload()
  }
  if (response.data.code !== 200 && response.data.code !== 30010) {
    Toast({
      message: response.data.msg,
      type: 'fail'
    })
    throw new Error(response.data.msg)
  }
  return response.data
}, error => {
  if (error.response.data.msg === '登录过期' && !process.env.VUE_APP_IS_ONLY_LONG_FORM) {
    localStorage.clear()
    location.reload()
  }
  return Promise.reject(error.response)
})

export default request
